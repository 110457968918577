<template>
    <b-container fluid>
        <b-row>
          <b-col md="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('pp_visit_request.visit_request') }}</h4>
              </template>
              <template v-slot:headerAction>
                 <b-button variant="primary" target="_blank" href="http://service.moa.gov.bd:8095/">{{ $t('pp_visit_request.virtual_visit') }}</b-button> &nbsp;
                <b-button class="ml-6" variant="primary" v-b-modal.modal-4 @click="resetId">{{ $t('pp_visit_request.visit_request') }}</b-button>
              </template>
              <template v-slot:body>
                  <b-overlay :show="loadingState">
                      <b-row>
                          <b-col md="12" class="table-responsive">
                              <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(budget_amount)="data">
                                  {{ $n(data.item.amount) }}
                                </template>
                                <template v-slot:cell(no_of_visitor)="data">
                                  {{ $n(data.item.no_of_visitor ? data.item.no_of_visitor : 1, { useGrouping: false }) }}
                                </template>
                                <template v-slot:cell(status)="data">
                                  {{ getStatus(data.item.status) }}
                                </template>
                                <template v-slot:cell(action)="data">
                                  <!-- <b-button v-if="data.item.status === 1" class="mr-1" title="Forward" v-b-modal.modal-1 variant=" iq-bg-success" size="sm" @click="feedback(data.item)">{{ $t('pp_visit_request.feedback') }}</b-button> -->
                                  <b-button v-if="data.item.status === 3 && data.item.tour_opt_set_status === 2 && data.item.feedbackExst === ''" class="mr-1" title="View" v-b-modal.modal-feedback variant=" iq-bg-success" size="sm" @click="feedBack(data.item)">{{ $t('pp_visit_request.feedback') }}</b-button>
                                  <b-button v-if="data.item.status === 2 && data.item.rview_dt_set_status === 2" title="Re-submit" v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="reSubmit(data.item)">{{ $t('pp_visit_request.resubmit') }}</b-button>
                                </template>
                              </b-table>
                              <b-pagination
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalRows"
                              />
                          </b-col>
                      </b-row>
                  </b-overlay>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('pp_visit_request.visit_request') + ' ' + $t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('pp_visit_request.visit_request') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :id="itemId" :items="items"/>
        </b-modal>
        <!-- <b-modal id="modal-feedback" size="lg" :title="$t('pp_visit_request.feedback')" ok-only ok-variant="danger">
          <Feedback :id="itemId" :orgid="orgId" :key="itemId"/>
        </b-modal> -->
        <b-modal id="modal-feedback" size="lg" :title="$t('pp_visit_request.feedback')" ok-only ok-variant="danger">
          <Rating :id="itemId" :orgid="orgId" :key="itemId"/>
        </b-modal>
    </b-container>
</template>
<script>
// import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
// import Feedback from './Feedback'
import Rating from './Rating'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { phyVisitListApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details, Rating
    },
    data () {
        return {
            search: {
            },
            items: '',
            editItemId: 0,
            itemId: 0,
            orgId: 0
        }
    },
    computed: {
        formTitle () {
            // return this.editItemId === 0 ? this.$t('pp_visit_request.visit_request') : this.$t('pp_visit_request.visit_request') + ' ' + this.$t('globalTrans.update')
            return this.editItemId === 0 ? this.$t('pp_visit_request.visit_request') : this.$t('pp_visit_request.visit_request') + ' ' + this.$t('pp_visit_request.resubmit')
        },
        columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('tour_management.request_id'), class: 'text-center' },
        { label: this.$t('pp_visit_request.request_type'), class: 'text-left' },
        { label: this.$t('pp_visit_request.number_of_visitor'), class: 'text-center' },
        { label: this.$t('pp_visit_request.visit_date'), class: 'text-left' },
        { label: this.$t('pp_visit_request.time_slot'), class: 'text-left' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('pp_visit_request.notes'), class: 'text-center' },
        { label: this.$t('pp_visit_request.tour_operator_name'), class: 'text-center' },
        { label: this.$t('pp_visit_request.tour_operator_mobil_no'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'request_id' },
          { key: 'request_type_bn' },
          { key: 'no_of_visitor' },
          { key: 'visit_date' },
          { key: 'time_slot' },
          { key: 'status' },
          { key: 'notes' },
          { key: 'tour_operator_name' },
          { key: 'tour_operator_mobil_no' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'request_id' },
          { key: 'request_type_en' },
          { key: 'no_of_visitor' },
          { key: 'visit_date' },
          { key: 'time_slot' },
          { key: 'status' },
          { key: 'notes' },
          { key: 'tour_operator_name' },
          { key: 'tour_operator_mobil_no' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    requestTypeList: function () {
        const list = [
        { value: 1, text_en: 'Individual', text_bn: 'স্বতন্ত্র' },
        { value: 2, text_en: 'Institutional', text_bn: 'প্রাতিষ্ঠানিক' }
        ]
        return list
    }
    },
    watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
    },
    mounted () {
    },
    methods: {
      reSubmit (item) {
      this.editItemId = item.id
    },
    feedBack (item) {
      this.itemId = item.id
      this.orgId = item.org_id
    },
      async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(virtualMuseumServiceBaseUrl, phyVisitListApi, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
     const listData = data.map(item => {
        const rqType = this.requestTypeList.find(rql => rql.value === item.request_type)
        const rqTypeObj = {
            request_type: rqType !== undefined ? rqType.value : '',
            request_type_en: rqType !== undefined ? rqType.text_en : '',
            request_type_bn: rqType !== undefined ? rqType.text_bn : ''
             }

        const timeSlotObj = {
            time_slot: new Date('1970-01-01T' + item.opening_time + 'Z').toLocaleString('en-US', {
              timeZone: 'UTC',
              hour: '2-digit', // numeric, 2-digit
              minute: '2-digit' // numeric, 2-digit
              }) + '---' + new Date('1970-01-01T' + item.closeing_time + 'Z').toLocaleString('en-US', {
              timeZone: 'UTC',
              hour: '2-digit', // numeric, 2-digit
              minute: '2-digit' // numeric, 2-digit
              })
             }
        return Object.assign({}, item, rqTypeObj, timeSlotObj)
      })
      return listData
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('pp_visit_request.reviewed')
      } else if (status === 3) {
        return this.$t('budget.approve')
      } else if (status === 4) {
        return this.$t('pp_visit_request.visited')
      } else if (status === 5) {
        return this.$t('tour_management.reject')
      }
    }
    }
}
</script>

<style scoped>
  .modal-title {
    flex-grow: 1 !important;
  }
 .btn {
    margin-left: 10px;
}
</style>
