<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                  <b-form  @submit.prevent="handleSubmit(register)" >
                    <p class="text-dark p-0 m-0">Select Star</p>
                    <span @click="formData.star_rate = 1" :class="(formData.star_rate >=1 ) ? `ri-star-fill text-orange` : `ri-star-fill`"></span>
                    <span @click="formData.star_rate = 2" :class="(formData.star_rate >=2 ) ? `ri-star-fill text-orange` : `ri-star-fill`"></span>
                    <span @click="formData.star_rate = 3" :class="(formData.star_rate >=3 ) ? `ri-star-fill text-orange` : `ri-star-fill`"></span>
                    <span @click="formData.star_rate = 4" :class="(formData.star_rate >=4 ) ? `ri-star-fill text-orange` : `ri-star-fill`"></span>
                    <span @click="formData.star_rate = 5" :class="(formData.star_rate >=5 ) ? `ri-star-fill text-orange` : `ri-star-fill`"></span>
                    <ValidationProvider name="Comment" vid='feedback' rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="12"
                        :label="$t('tour_management.feedback')"
                        label-for="feedback"
                        slot-scope="{ valid, errors }"
                      >
                        <b-form-textarea
                          id="feedback"
                          rows="5"
                          v-model="formData.feedback"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <b-row>
                        <b-col class="text-right">
                          <b-button class="" type="submit" variant="primary">{{ saveBtnName}}</b-button>
                        </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
                </b-col>
              </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>

import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { phyVisitRatingApi } from '../../../api/routes'

export default {
  props: ['id', 'orgid'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    core.index()
  },
  created () {
    // this.formData.application_id = this.application.id
    // this.formData.application_type = this.application.application_type
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      loading: false,
      formData: {
        org_id: this.orgid,
        request_id: this.id,
        star_rate: 5,
        feedback: ''
      }
    }
  },
  computed: {
    // loading () {
    //   return this.$store.state.ExternalUserIrrigation.static.loading
    // }
  },
  methods: {
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      let result = null
      const loadingState = { loading: false, listReload: false }
      // result = await RestApi.postData(virtualMuseumServiceBaseUrl, 'app-rating/store', this.formData)
      result = await RestApi.postData(virtualMuseumServiceBaseUrl, phyVisitRatingApi, this.formData)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      this.loading = false
      if (result.success) {
          this.$toast.success({
            title: 'Success',
            message: result.message
          })
        this.$bvModal.hide('modal-feedback')
      } else {
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
      }
    }
  }
}
</script>
<style scoped>
.ri-star-fill {
    font-size: 25px;
    margin-left: 5px;
    color: gray;
    cursor: pointer;
}
.text-orange {
    color: orange;
}
</style>
