<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:body>
             <b-overlay :show="loadingState">
            <b-row>
              <b-col sm="12" class="form-for-textarea">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" enctype="multipart/form-data">
                    <b-row>
                      <b-col sm="4">
                          <ValidationProvider name="Organigation" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="4">
                          <ValidationProvider name="Application Type"  vid="request_type" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="request_type"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('pp_visit_request.request_type')}} <span class="text-danger">*</span>
                              </template>
                               <b-form-select
                                plain
                                v-model="formData.request_type"
                                :options="requestTypeList"
                                id="request_type"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-if="showInstitute">
                          <ValidationProvider name="Institute Name" vid="institute_name">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="institute_name"
                            >
                            <template v-slot:label>
                            {{ $t('pp_visit_request.institute_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="institute_name"
                            plain
                            v-model="formData.institute_name"
                            :disabled = "institute_name_action"
                            >
                            </b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="4" v-if="showInstitute">
                          <ValidationProvider name="Number Of Visit" vid="number_of_visitor">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="number_of_visitor"
                            >
                            <template v-slot:label>
                            {{ $t('pp_visit_request.number_of_visitor') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="institute_name"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            plain
                            v-model="formData.number_of_visitor"
                            :disabled = "institute_name_action"
                            >
                            </b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="4">
                          <ValidationProvider name="Contact Person Name" vid="contact_person_name" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="contact_person_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('pp_visit_request.contact_person_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="contact_person_name"
                            plain
                            v-model="formData.contact_person_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="4">
                          <ValidationProvider name="NID" vid="nid_no" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="nid_no"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('pp_visit_request.nid_no') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="nid_no"
                            plain
                            v-model="formData.nid_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="4">
                          <ValidationProvider name="Mobile No" vid="mobile_no" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="mobile_no"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('pp_visit_request.mobile_no') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="mobile_no"
                            plain
                            v-model="formData.mobile_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="4">
                          <ValidationProvider name="Email" vid="email" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="email"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('pp_visit_request.email') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="email"
                            plain
                            v-model="formData.email"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                         <b-col sm="4">
                          <ValidationProvider name="Visit Date" vid="visit_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="visit_date"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{$t('pp_visit_request.visit_date')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="visit_date"
                              v-model="formData.visit_date"
                              :placeholder="$t('globalTrans.select_date')"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                              <div v-if="custoErrFlag"
                                class="invalid-feedback1"
                                style="width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #dc3545;"
                                >
                                {{warnMsg ? warnMsg : ''}}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                        <b-col sm="4">
                          <ValidationProvider name="Slot"  vid="slot_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="slot_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('pp_visit_request.time')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.slot_id"
                                :options="slotList"
                                id="slot_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <!-- <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option> -->
                                <b-form-select-option :value="0">{{ slotLoadingFlag ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="4">
                           <ValidationProvider name="Remarks" vid="remarks">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="remarks"
                                >
                                <template v-slot:label>
                                {{$t('pp_visit_request.remarks')}}
                                </template>
                                <b-form-textarea
                                id="remarks"
                                v-model="formData.remarks"
                                ></b-form-textarea>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      <b-button variant="danger" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.form-for-textarea textarea{ line-height: 18px !important;height: 54px !important; }
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { dateWiseSlotApi, phyVisitStoreApi, phyVisitUpdateApi } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      // this.formData.sell_details = []
      // const fertilizerDetailsInfo = tmp.dealer_sell_details
      // this.allocaitonStockInfo.filter(item => {
      //   const fertiDetailsItem = fertilizerDetailsInfo.find(portDetailsObj => portDetailsObj.fertilizer_id === item.fertilizer_id)
      //   const portInfoItem = {
      //     sell_details_id: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.id : 0,
      //     fertilizer_id: item.fertilizer_id,
      //     quantity: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.quantity : '',
      //     allocation_qty: item.allocation_qty,
      //     sell_available_qty: item.sell_available_qty,
      //     fertilizer_name: item.fertilizer_name,
      //     fertilizer_name_bn: item.fertilizer_name_bn
      //   }
      //   this.formData.sell_details.push(portInfoItem)
      // })
    }
  },
  mounted () {
    flatpickr('#visit_date', { minDate: 'today' })
    core.index()
  },
  data () {
    return {
      loadingState: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('pp_visit_request.visit_request_save'),
      formData: {
        org_id: 0,
        request_type: 0,
        institute_name: '',
        number_of_visitor: '',
        contact_person_name: '',
        nid_no: '',
        mobile_no: '',
        email: '',
        visit_date: '',
        slot_id: 0,
        remarks: ''
      },
      slotList: [],
      institute_name_action: true,
      custoErrFlag: false,
      slotLoadingFlag: false,
      showInstitute: false,
      warnMsg: ''
    }
  },
  computed: {
    orgList: function () {
          const objectData = this.$store.state.VirtualMuseum.commonObj.organizationProfileList.filter(item => item.status === 0)
          // const objectData = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
        },
    requestTypeList: function () {
        const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Individual' : 'স্বতন্ত্র' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Institutional' : 'প্রাতিষ্ঠানিক' }
        ]
        return list
    },
    currentLocale: function () {
        return this.$i18n.locale
      }
  },
  watch: {
    'formData.request_type': function (newVal, oldVal) {
        if (newVal !== oldVal && newVal === 2) {
          this.institute_name_action = false
          this.showInstitute = true
        } else {
          this.institute_name_action = true
          this.showInstitute = false
        }
    },
    'formData.org_id': function (newVal, oldVal) {
      if ((newVal !== oldVal) && (this.formData.visit_date !== '')) {
          this.getDateWiseSlot(newVal)
        }
    },
    'formData.visit_date': function (newVal, oldVal) {
      if ((newVal !== oldVal) && (this.formData.org_id > 0)) {
          this.getDateWiseSlot(newVal)
        }
    }
  },
  methods: {
    getFormData () {
      // this.slotList = []
      // let slotObj = {}
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      // slotObj = {
      //   value: tmpData.id,
      //   text: tmpData.time_slot
      // }
      // this.slotList.push(slotObj)
      // this.slotList = [{
      //   value: tmpData.id,
      //   text: tmpData.time_slot
      // }]

      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loadingState = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.putData(virtualMuseumServiceBaseUrl, `${phyVisitUpdateApi}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(virtualMuseumServiceBaseUrl, phyVisitStoreApi, this.formData)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)
        this.loadingState = false
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.stockMsg = result.message
        this.errorLists = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    },
    async getDateWiseSlot () {
      this.slotList = []
      this.loadingState = true
      this.custoErrFlag = false
      this.slotLoadingFlag = true
      this.warnMsg = ''

      let result = []
      const params = Object.assign({}, { org_id: this.formData.org_id, visit_date: this.formData.visit_date })
        result = await RestApi.postData(virtualMuseumServiceBaseUrl, dateWiseSlotApi, params)
      if (result.success) {
           if (result.offDay) {
             this.custoErrFlag = true
             this.warnMsg = result.message
           } else if (result.slotNotFound) {
             this.custoErrFlag = true
             this.warnMsg = result.message
           } else if (result.slotAllBooked) {
             this.custoErrFlag = true
             this.warnMsg = result.message
           } else if (result.slotFound) {
             if (this.id) {
                this.slotList = result.slotList
                // ------------Start--------------
                 let slotObj = {}
                const edtData = this.$store.state.list.find(item => item.id === this.id)
                slotObj = {
                  value: edtData.slot_id,
                  text: edtData.time_slot
                }
                const extSlotData = this.slotList.find(sinSl => sinSl.val === edtData.slot_id)
                if (!extSlotData) {
                  this.slotList.push(slotObj)
                }
                // ------------End----------------
             } else {
                this.slotList = result.slotList
             }
           }
      }
      this.slotLoadingFlag = false
      this.loadingState = false
    }
  }
}
</script>
